import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import loadable from '@loadable/component';

const Social = loadable(() =>
  import('../../../../../components/social/social')
);

export default function PackageStickyNav(props) {
  const { post } = props;

  const [open, setOpen] = useState(false);

  return (
    <nav className="package-sticky-nav">
      <a href="/the-new-worker-moment">The New Worker Moment</a>

      <div className="social-section" onClick={() => setOpen(!open)}>
        <img
          alt="share"
          src="//assets.fastcompany.com/image/upload/v1620003529/fcweb/share_icon.svg"
        />
      </div>

      {open && (
        <div className="social-buttons">
          <Social
            uri={get(post, 'link')}
            text={get(
              post,
              'customFields.facebook_social_copy',
              get(post, 'title', null)
            )}
          />
        </div>
      )}
    </nav>
  );
}

PackageStickyNav.propTypes = {
  post: PropTypes.shape({}).isRequired
};
